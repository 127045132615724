/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application', defer: false %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import Rails from '@rails/ujs'

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
window.Rails = Rails

// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
Rails.start()
